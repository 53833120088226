@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* *{
    font-family: Roboto;
 } */

 /* 
 color : eaf5f7
 003862

 background-image: linear-gradient(150deg,#ffba61 0%,#ffe970 100%)!important;

 */

 .home-bg{
    /* background-repeat: no-repeat;    
     z-index: -5;    
    background: url('https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Fcircle-scatter-haikei.png?alt=media&token=9b35b230-1728-4893-9a6f-db9763eaf882');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; */
    background: white;
    background: linear-gradient(180deg, white 0%, white 30%, rgba(0, 33, 71, 0.7) 60%,#ffffff 100%);
    /* background-color: linear-gradient(180deg,#0f1111 0%,#ffffff 100%) ; */
}

.typed-text{
    font-family: 'Quicksand', sans-serif;
    color: #002147;
    font-weight: bold;
    font-size: 3.5rem;
    text-shadow: 3px 3px 4px #085910;
}

.row-header{
    margin:0px auto;
    padding:0px auto;
}

.home-page-alumni-div{
    /* background-color: grey; */
    border: 1px solid black;
    border-radius: 5%;
    padding: 5%;
    background-color: #000;
}

@media screen and (max-width : 480px) {
    .home-page-alumni-div{
        border-radius: 50px;
    }
}

.homepage-alumnilist{
        border-radius: 10px;
        background-color: whitesmoke;
        border: 0.8px solid rgba(4, 59, 179, 0.2);
        padding: 12px;
        font-family: 'Proza Libre', sans-serif;
        /* font-family: 'Quicksand', sans-serif; */
        box-shadow: 4px 4px 0px grey;
}

.homepage-alumnilist img{
    /* box-shadow: 0px 2px 4px grey, 0px 4px 8px grey; */
    /* border: 1px solid #000; */
    border-radius: 8%;
}

.alumni-heading{
    color: whitesmoke;
    font-family: 'Quicksand', sans-serif;

    /* padding: 0px; */
    /* color: black; */
    margin-left: 10px;
    /* border-bottom: 2px solid #051d2e; */
    /* border-left: 4px solid green ; */
    margin-bottom: 20px;
}

.carousels {
    display: block;
    margin-top: 0px;
    max-width: 100%;
    height: auto;
    background-color: linear-gradient(180deg,#012f63 80%,#ffffff 100%);
    transition: opacity .80s ease-in-out;
}

.carousel-captions{
    /* background-color: blue; */
    position: absolute;
    right: 15%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700 !important;
    font-size: 4rem;
    text-shadow: 4px -4px 0px black;
    bottom: 25%;
    /* display: block; */

    color: whitesmoke;
}



/* .carousel-text-div{
    border: 4px solid black;
} */

.carousel-text{
    /* display: block; */
    /* background-color: #059191; */
    position: absolute;
    right: 15%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500 !important;
    font-size: 1.4rem;
    text-shadow: 1.5px 1.5px 0px grey;
    bottom: 15%;
    /* color: #082032; */
    /* color: whitesmoke; */
}

.carousels-btn{
    position: absolute;
    right: 15%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700 !important;
    font-size: 4rem;
    /* text-shadow: 4px -4px 0px black; */
    bottom: 25%;
    /* display: block; */
    color: whitesmoke;
    transition: all 4s ease;
    font-weight: 400;
    /* align-items: center; */
    display: inline-block;
    transition: all 0.4s;
    border: 2px solid rgb(1, 1, 54);
    /* color: rgb(45, 4, 156, 1); */
    color: #002147;
    background: #002147;
    font-size: 1.2rem;
}

.carousels-btn:hover{
    font-size: 1.4rem;
    /* background: #002147; */
    background: rgba(128, 128, 128, 0.40);
    color: whitesmoke;
    color: black !important;
}

.carousels-btn a {
    color: whitesmoke !important;
}

.carousels-btn:hover a {
    color: black !important;
}

@media screen and (max-width : 480px)  {
    .carousel-captions{
        text-shadow: 0.4px 0.4px 0px black;
        font-size: 1.5rem;
        bottom: 30%;
    }
    .carousel-text{
        bottom: 10%;
        font-size: 1rem;
    }
}

.carouselImage{
    margin-top: 10vh;
    width:100%;
    height:75vh;
    /* background-size:cover ; */
    /* object-fit:center; */
    /* border-bottom-right-radius: 6%;
    border-bottom-left-radius: 6%; */
    position: relative;
}

.carouselImage2{
    /* border-bottom-right-radius: 5%;
    border-bottom-left-radius: 5%; */
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    /* opacity: 0.9; */
    bottom: 0;
    left: 0;
    margin: 0 !important;
    position: absolute;
}

.img-carousel{
    position: absolute;
    z-index: 99;
}

.about-div{
    overflow: hidden;
    background-color: linear-gradient(180deg,#059191 0%,#ffffff 100%);
}
.about-div-content{
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    /* border-radius: 4px; */
    padding: 20px;
    color: #002147;
    background-color: #54eb9d;
}

.jumb{
    background-size: cover;
    background-position:top;
    background-attachment: fixed;
}

@media screen and (max-width : 480px) {
    .carouselImage {height: 320px;}
}

.breadCrumb{
    margin-top: 80px;
}
.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

.short-desc{
    font-family: 'Proza Libre', sans-serif;
    font-family: 'Quicksand', sans-serif;
    background-color: #c8fde2;
    color: #051d2e;
    /* #c8fde2; */
    /* #18ab60; */
    /* background-color: rgb(7, 6, 27); */
    /* color: white; */
    margin: 5px;
    border: 0.5px solid rgb(29, 31, 31, 0.5);
    /* border-left: 6px solid cyan; */

    padding: 2vw;
    box-shadow: 0px 0px 8px 6px rgba(230, 196, 196, 0.5);
}
.short-desc h2{
    font-family: 'Proza Libre', sans-serif;
    color: #002147;
    font-weight: bold;
    text-shadow: 2px 2px 2px green;
}

/* .short-desc img{
    border-radius: 50%;
} */

.footer{
    background-color: #051d2e;
    /* #0f0f0f; */
    /* #ff0066;  */
    /* #384c64;#D1C4E9; */
    
    margin:0px auto;
    /* margin-top: 0.8rem; */
    color: #54eb9d;
    padding: 20px 0px 20px 0px;
}

.btn-i{
    font-size: 1.1rem;
    align-items: center;
    justify-content: center !important;
    color: white;
    border-radius: 40%;
    
}
.btn-i:hover{
    color: grey;
    /* border: 0.8px solid black; */
}

.footer-link{
    color: #5cb6f9 !important;
}

.footer-div{
    /* overflow: hidden; */
    background-color: white;
}

.footerPart{
    margin-top:-2.5rem;width:100%;height:12rem;display:block;
    
}

.footerPart2{
    transform: rotate(180deg);
    margin-top:-2.5rem;width:100%;height:12rem;display:block
}

/* Jumbotron */

.jumbotron {
    padding:150px 60px 150px 60px;
    margin-top: 55px;
    margin-bottom: 0rem;
    background-image:  url('https://firebasestorage.googleapis.com/v0/b/mshtserver.appspot.com/o/images%2FBanner.png?alt=media&token=dc0271ed-fa52-4cf5-be60-6bbd4bdb2db1');
    /* #1d014e */
    /* background-size: 100%; */
    background-repeat: no-repeat;
    color:floralwhite;
    
}

.blog-Jumbotron {
    left: 0;
    margin: 0;
    height: 60vh !important;

    padding:150px 60px 150px 60px;
    /* clip-path: polygon(0 0, 0 49%, 10% 46%, 21% 47%, 33% 52%, 45% 57%, 55% 60%, 68% 61%, 79% 59%, 91% 55%, 100% 50%, 100% 0); */
    margin-bottom: 0rem;
     /* Url('https://firebasestorage.googleapis.com/v0/b/mshtserver.appspot.com/o/images%2FBanner.png?alt=media&token=dc0271ed-fa52-4cf5-be60-6bbd4bdb2db1'); */
    /* #1d014e */
    /* background-size: 100%; */
    background-image:  url('https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FJumbotron%2FResearch%20page%2FResearch-Jumbotron.svg?alt=media&token=e8fabc27-d9f9-40ce-a96a-304a3776ce78');
    background-repeat: no-repeat;
    color: rgb(192, 182, 182);
    font-family: 'Quicksand', sans-serif;

    background-size: cover;
    background-position: center;
    font-size: 2.8rem;
    background-attachment: fixed;
}

.about-Jumbotron {
    left: 0;
    color: rgb(192, 182, 182);
    margin: 0;
    height: 60vh !important;
    padding:150px 60px 150px 60px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FBackgrounds%2Fsymbol-scatter-1.svg?alt=media&token=575f4e8a-1af1-4d7d-ae42-7b65e452128c');
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Fpolygon-scatter-haikei%20(1).png?alt=media&token=d4cdffbf-c960-48a1-9027-a3339d68d304'); */
    /* background-image:  url('https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Flow-poly-grid-haikei%20(2).png?alt=media&token=f37004db-26ea-48b8-95cb-c3106ec49072'); */
    /* clip-path: polygon(0 0, 0 49%, 10% 46%, 21% 47%, 33% 52%, 45% 57%, 55% 60%, 68% 61%, 79% 59%, 91% 55%, 100% 50%, 100% 0); */
    margin-bottom: 0rem;
    font-family: 'Quicksand', sans-serif;
     /* Url('https://firebasestorage.googleapis.com/v0/b/mshtserver.appspot.com/o/images%2FBanner.png?alt=media&token=dc0271ed-fa52-4cf5-be60-6bbd4bdb2db1'); */
    /* #1d014e */
    /* background-size: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.alumni-Jumbotron {
    font-family: 'Quicksand', sans-serif;

    left: 0;
    color: rgb(192, 182, 182);
    margin: 0;
    height: 60vh !important;
    padding:150px 60px 150px 60px;
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Fsymbol-scatter-haikei.png?alt=media&token=6423cfb9-bae0-4093-a48b-a6aea9085292'); */
    background-image: url('https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FBackgrounds%2Fpolygon-scatter-3.svg?alt=media&token=290afff6-e932-4cb9-89ea-8a10a3dffc15');
    /* background-image:  url('https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Flow-poly-grid-haikei%20(2).png?alt=media&token=f37004db-26ea-48b8-95cb-c3106ec49072'); */
    /* clip-path: polygon(0 0, 0 49%, 10% 46%, 21% 47%, 33% 52%, 45% 57%, 55% 60%, 68% 61%, 79% 59%, 91% 55%, 100% 50%, 100% 0); */
    margin-bottom: 0rem;
     /* Url('https://firebasestorage.googleapis.com/v0/b/mshtserver.appspot.com/o/images%2FBanner.png?alt=media&token=dc0271ed-fa52-4cf5-be60-6bbd4bdb2db1'); */
    /* #1d014e */
    /* background-size: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

@media screen and (max-width : 480px) {
    .jumbotron {width: 100%; height: 100px; padding: 0px;}
}

/* About Page */

.about-page-bg{

    background:  linear-gradient(180deg,rgba(84, 235, 157, 0.3) 0%, white 50%,#ffffff 100%);
}

.about-us{
    /* font-family: 'Proza Libre', sans-serif; */
    font-family: 'Quicksand', sans-serif;
}

.sample-cards {
    padding: 10px;
    margin: 4px;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 10px;
    width: 300px;
    height: 450px;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
/* border: 0.1px solid black; */
}

@media screen and (max-width: 480px){
    .sample-cards{
        width: 100%;
    }
}

.sample-cards .card-images {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 300px; */
    height: 100%;
    z-index: 2;
    background-color: #000;
    transition: .5s;
}

.sample-cards .card-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sample-cards:hover img {
    opacity: 0;
    transition: .5s;
}

.sample-cards:hover {
    box-shadow: 0px 0px 8px 6px rgba(107, 92, 92, 0.5), 0px 0px 12px 8px rgba(107, 92, 92, 0.5);

}

    .sample-cards:hover .card-images {
    /* -webkit-transform: translateY(-100px);
            transform: translateY(-100px); */
    transition: all .9s;
    }


    .sample-cards:hover .social-icons li a {
    transform: translateY(0px);
    opacity: 1;
    }

    .sample-cards:hover li:nth-child(1) a {
    transition-delay: .1s;
    }

    .sample-cards:hover li:nth-child(2) a {
    transition-delay: .2s;
    }

    .sample-cards:hover li:nth-child(3) a {
        transition-delay: .3s;
    }

    .sample-cards:hover li:nth-child(4) a {
        transition-delay: .4s;
    }

    .sample-cards:hover .info {
        bottom: 0;
    }

    .info {
        position: absolute;
        bottom: 400px;
        left: 0;
        background: #fff;
        width: 100%;
        height: 120px;
        z-index: 1;
        padding: 10px;
        transition: .6s;
        font-family: 'Proza Libre', sans-serif;
        font-family: 'Quicksand', sans-serif;
    }

    .info h2 {
        margin: 30px 0;
        padding: 0;
        color: #000;
        text-align: center;
    }

    .info h2 .job-title {
        font-size: 16px;
        line-height: 2;
        /* color: #333; */
        color: black;
        font-weight: 300;
        display: block;
    }

    .sample-cards:hover .info {
        bottom: 0;
    }


.property-card
{
  margin: 1.8rem;
  height:18em;
  width:18em;
  display:flex;
  flex-direction:column;
  position:relative;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius:16px;
  overflow:hidden;
  font-family: 'Quicksand', sans-serif;
  box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 20px #e1e1e348;
}

/* Top Half of card, image. */

.property-image
{
  height:13em;
  width:18em;
  padding:12px;
  position:Absolute;
  top:0px;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* background-image:url('https://cdn.photographylife.com/wp-content/uploads/2017/01/What-is-landscape-photography.jpg'); */
  /* background-size:cover; */
  /* background-repeat:no-repeat; */
}

/* Bottom Card Section */

.property-description
{
  background-color: #FAFAFC;
  height:7em;
  width:18em;
  position:absolute;
  bottom:0em;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align:center;
}

/* Social Icons */

.property-social-icons
{
  width:1em;
  height:1em;
  background-color:black;
  position:absolute;
  bottom:1em;
  left:1em;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Property Cards Hover States */

.property-card:hover .property-description
{
  height:0em;
  padding:0px 1em;
}
.property-card:hover .property-image
{
    padding: 0px;
  height:18em;
}

.property-card:hover .property-social-icons
{
  background-color:white;
}

.property-card:hover .property-social-icons:hover
{
  background-color:blue;
  cursor:pointer;
}




.teams{
    font-family: 'Quicksand', sans-serif;
}

.team-img{
    margin: 10px;
    width: 180px;
    height: 180px;
    border-radius: 12px;
    /* border: 1px solid #002147; */
}

address{
    font-family: 'Proza Libre', sans-serif;
    font-size:80%;
    margin:0px;
    color: grey;
}
.emoji{
    color: red;
}

.spin{
    color: green;
    margin-left: 40%;
    margin-top: 100px;
    /* top: 50%;
    left: 50%; */
    /* #fb3958; */
}

@media screen and (max-width: 480px) {
    .spin{margin-left: 15%;}
}

@media screen and (max-width: 720px) {
    .spin{margin-left: 20%;}
}

.spinner-text{
    color: grey;
}

/* Header */

.user-name{
    color: #002147 !important;
}

.navbar-dark .navbar-nav {
    /* background: white; */
    color: rgb(19, 15, 15);
    /* transition: border-bottom 500ms;
    transition-timing-function:step-end ; */
}

.navbar-dark .navbar-nav .nav-link {
    color: grey;
    font-size: 1.1em;
    position: relative;
}
.navbar .navbar-nav .nav-link:hover {
    color: black;
}
.navbar .navbar-nav .nav-link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: black !important;
    color: transparent;
    width: 0%;
    content: '.';
    height: 3px;
}
.navbar .navbar-nav .nav-link:hover::after {
    width: 100%;
}

.navbar .navbar-nav .nav-link:hover::after:active {
    width: 100%;
}
  
.nav-link:after {
    content: '';
    width: 100%;
    transform: scaleX(0);
    /* background-color: #0087ca; */
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
}
  
.nav-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
    width: 100%;
    color: black;
    font-size: 1.1rem;
    font-weight: bold;
    /* border-bottom: 3px solid red; */
}

.navbar-dark {
    /* background-image: linear-gradient(90deg,#f92c8b 0%,#b02cd6 100%); */
    /* border-bottom: 1.5px solid black; */
    box-shadow: 3px 3px 4px rgb(0, 0, 0, 0.8);
    font-weight: 500;
    font-family: 'Quicksand', serif;
    /* font-family: 'Proza Libre', sans-serif; */
    /* background-color: #130e23 ; */
    background: white ;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 33, 71)' stroke-width='2.5' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.page-enter {
    opacity: 0.01;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 500ms ease-in;
}

.page-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0.01;
    transform: translateX(100%);
    transition: all 300ms ease-out;
}

/* Post page */

.post-carousel-image, .post-carousel{
    width: 100%;
    height: 80vh;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    z-index: 0;
}

@media screen and (max-width: 480px) {
    .post-carousel-image, .post-carousel{ width: 100%; height: 60vh;}
}

.carousel-control{
    opacity: 0;
}

.carousel-control:hover{
    opacity: 1;
}

.post-card{
    position: relative;
    width: 100%;
    height: auto;
    /* padding: 20px; */
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    border-radius: 14px;
    /* border: 4px solid black; */
    /* padding: 20px; */
    box-shadow: 0px 0px 8px 6px rgba(107, 92, 92, 0.253);
}

.carousel-btn{
    border: none;
    border-radius: 50%;
    padding: 3px;
    /* height: 28px; */
    /* top: 1%; */
    position: absolute;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.555);
    /* opacity: 0; */
    left: 95%;
    opacity: 1;
}

@media screen and (max-width: 480px) {
    .carousel-btn{left: 90%;}
}

@media screen and (max-width : 480px) {
    .post-btn1 { background-color: #3f729b; color: white; font-family: 'Proza Libre', sans-serif; padding: 8px;   }
    .post-btn2 { background-color: #fb3958; color: white; font-family: 'Proza Libre', sans-serif; padding: 8px;   }
}

.post-card:hover{
    box-shadow: 0px 0px 8px 6px rgba(63, 49, 49, 0.6);
}

.post-desc{
    /* background-color: black; */
    /* rgba(1, 1, 19, 0.9); */
    border: 0.1px solid rgba(0, 0, 0, 0.562);
    padding: 10px;
    font-size: 1.4rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.post-btn1 ,.post-btn2 {
    font-family: 'Proza Libre', sans-serif;
    font-size: 1rem;
    border: .01px solid rgba(0, 0, 0, 0.61);
    display: inline-block;
    border-radius: 6px;
    width: 95%;
    height: auto;
    padding: 5px;
    margin: 5px;
}

.post-btn1:hover{
    background-color: #3f729b;
    /* rgba(21, 10, 170, 0.918); */
    color: white;
}

.post-btn2:hover{
    color: white;
    background-color: #fb3958;
    /* rgba(218, 13, 13, 0.911); */
}

.App button:visited{
    background-color: black;
    color: lightgrey;
}

/* Blog menu page */

.blog-page-bg{
    background: linear-gradient(180deg,#88e6f7 0%,#ffffff 100%) ;
    /* background:  linear-gradient(180deg,#54eb9d 0%,#ffffff 100%); */

    /* background-image: linear-gradient(150deg,#997deeb1 0%,#ffffff 100%)!important; */
}

.blog-card{
    text-decoration: none;
    box-shadow: 0px 0px 8px 6px rgba(85, 84, 84, 0.3);
}

.blog-card:hover{
    box-shadow: 0px 0px 8px 6px rgba(50, 47, 47, 0.8);
    text-decoration: none;
}



/* Link{
    text-decoration: none;
} */

/* Blog page */

.blog-page{
    /* padding: 2rem; */
    box-shadow: 0px 0px 8px 6px rgba(85, 79, 79, 0.253);
}

@media screen and (max-width: 480px){
    .blog-page{
        padding: 0.1px;
    }
}

.m-1{
    margin: 0 !important;
}

.blog-img{
    /* width: 60%; */
    border: 0.2px solid rgba(0, 0, 0, 0.363);
    height: 80vh;
}

@media screen and (max-width: 480px){
    .blog-img{
        width: 92%;
        margin: auto;
        margin-top: 6px;
        height: 65vh;
    }
    .card-body{
        padding: 0px;
        margin: 0;
        /* margin: 14px; */
    }
    .blog-page{
        padding: 0px;
    }

    .blog-desc p{
        padding: 10px;
    }
}

.blog-title{
    /* font-family: 'Lobster', cursive; */
    /* font-family: 'Roboto', cursive; */
    font-family: 'Proza Libre', sans-serif;
    font-size: 2.1rem;
    padding: 10px;
    letter-spacing: 0.2rem;
    font-weight: bold;
}

.author-name{
    color: rgb(5, 55, 192);
    font-weight: 600;
}

.blog-shortdesc{
    color: grey;
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 400;
}

.blog-det{
    /* /* font-family: 'Lobster', cursive;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */

    font-family: 'Quicksand', sans-serif;    
    /* font-family: 'Merriweather', serif; */
    /* font-family: 'Proza Libre', sans-serif; */
    font-size: 1rem;
    padding: 5px;
    letter-spacing: 0.06rem;
    color: rgb(78, 76, 76);
}

.blog-det img{
    width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 480px){
    .blog-det img{
        width: 90%;
    }
}



.card-title{
    color: #0f0f0f;
    justify-content: center;
}

.ref{
    margin: 5px;
    padding: 10px;
    font-size: 1rem;
    border: 0.5px solid rgba(34, 32, 32, 0.425);
    border-left: 4px solid blue;
    font-family: 'Roboto';
}

.comments-header{
    margin: 8px;
    font-family: Roboto;
    font-size: 2.2rem;
}

.comment-mssg{
    font-size: 1.2rem;
}

.comment-btn{
    margin-left: 2%;
}

.avatar{
    margin: 8px;
    margin-right: 20px;
}

.modal{
    background: rgba(252, 250, 252, 0.25);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 15.0px ); 
     -webkit-backdrop-filter: blur( 15.0px ); 
    border-radius: solid 10px;
    border: 1px solid rgba(11, 1, 34, 1);
    font-family: 'Quicksand', sans-serif;
    /* rgba( 255, 255, 255, 1 ); */
}

.modal-header{
    /* font-family: cursive; */
    /* background-color: rgba(11, 1, 34, 1); */
    /* font-weight: bold; */
    background-color: #54eb9d;
    color: #051d2e;
    box-shadow: 0px 8px 0px 0px rgba(230, 196, 196, 0.5);
}

.modal-body{
    /* padding: 25px; */
    background-color: rgba( 255, 255, 255, 0.15);
}

.form-check-input {
    position: absolute;
    margin-top: 0.4rem;
    margin-left: -1.25rem;
}

.form-check-label {
    margin-left: 1rem;
    margin-bottom: 0px;
}

.comment-modal-header{
    font-family: 'Proza Libre', sans-serif;
    width: 90%;
    margin-left: 5%;
    margin-top: -3%;
}

/* .modal{
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
} */

/* Alumni Page */

.alumni-page-bg{
    background: linear-gradient(180deg,#0c71c39d 0%,#ffffff 100%)!important;
    /* background-color: white; */
    /* margin: 0; */
    /* 003862; */
}

.bd{
    background: white;
    /* border: 0.1rem solid grey; */
    border: 2px solid rgba(4, 59, 179, 0.2);
    padding: 12px;
    font-family: 'Proza Libre', sans-serif;
    font-family: 'Quicksand', sans-serif;
    box-shadow: 6px 6px 8px rgba(4, 59, 179, 0.61);
}

.bd img{
    box-shadow: 0px 2px 4px grey, 0px 4px 8px grey;
}

.headng{
    z-index: 998;
    /* font-family: 'Proza Libre', sans-serif;
     */
    font-family: 'Quicksand', sans-serif;

    /* padding: 0px; */
    color: black;
    margin-left: 10px;
    border-bottom: 2px solid #051d2e;
    /* border-left: 4px solid green ; */
    margin-bottom: 20px;
}

/* .headng:hover{
    color: rgb(45, 4, 156, 0.75);
} */

.featured-heading{
    z-index: 998;
    /* font-family: 'Proza Libre', sans-serif;
     */
    font-family: 'Quicksand', sans-serif;

    /* padding: 0px; */
    color: black;
    margin-left: 10px;
    /* border-bottom: 2px solid #051d2e; */
    /* border-left: 4px solid green ; */
    margin-bottom: 20px;
}

.feedback-heading{
    padding: 10px;
}

.inpt{
    width: 100%;
    border: none;
    border: 1px solid #051d2e;

    /* border: 1px solid rgb(45, 4, 156, 0.75); */
    /* border-left: 2px solid blue; */
    /* border-bottom: 2px solid rgb(45, 4, 156, 0.75) ; */
}

.inptMssg{
    border: 1.5px solid #051d2e;
     /* rg(45, 4, 156, 0.75); */
}

/* Contact Page */

.contact-page-bg{
    background: linear-gradient(180deg,#d1ebff 0%,#ffffff 100%);
}

.fonts{
    font-family: 'Proza Libre', sans-serif;
}

.btn-call{
    background-color: #18ab60;

    color: white;
}
.btn-insta{
    background-color: #051d2e;

    color: white;
}
.btn-mail{
    background-color: #085910;
    color: white;

}

.form{
    font-family: 'Proza Libre', sans-serif;
    box-shadow: 4px red;
    /* margin-left: 10%; */
    margin: auto;
}
.form-section{
    /* margin-left: 20%; */
    /* background-color: #d3d3d3; */
    padding: 40px;
    background-size: contain;
    /* margin-bottom: 0px; */
}

/* Blog submit button */
.button{
    transition: all 4s ease;
    font-weight: 400;
    font-family: 'Proza Libre', sans-serif;
    /* align-items: center; */
    display: inline-block;
    transition: all 0.4s;
    border: 2px solid rgb(1, 1, 54);
    /* color: rgb(45, 4, 156, 1); */
    color: #002147;
    font-size: 1.2rem;
}

.button:hover{
    font-size: 1.4rem;
    background: #002147;
    color: #54eb9d;
}

section .waveMain{
    display: block;
    /* position: initial; */
    position: relative;
    /* display: block; */
    margin: 0%;
    margin-bottom: 500px;
    overflow: hidden;
    padding: 0% !important;
    height: 200px; 
    width: 100%;
}

section .wave{
    display: block;
    /* position: absolute; */
    width: 100%;
    height: 200px;
    background: url('https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Fwave%20(10).png?alt=media&token=ae191f2b-27f1-4258-92a3-f614379fa24c');
    background-size: 1100px 200px;
}

section .wave.wave1{
    animation: anim 15s linear infinite;
    opacity: 1;
    position: absolute;
    z-index: 1000;
    animation-delay: 0s;
    bottom: 15;
}

@keyframes anim{
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: 1000px;
    }
}

section .wave.wave2{
    opacity: 0.6;
    position: absolute;
    z-index: 999;
    animation-delay: -2s;
    animation: anim2 30s linear infinite;

    /* bottom: 1000px; */
}


@keyframes anim2{
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: -1000px;
    }
}

section .wave.wave3{
    animation: anim3 45s linear infinite;
    opacity: 0.4;
    position: absolute;
    z-index: 997;
    animation-delay: -4s;
    bottom: 15;
}

@keyframes anim3{
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: 1000px;
    }
}


/* Slider Component  */

.slider_title {
    font-size: 5vw;
    color: tomato;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2vw;
}
    
.sample-wrapper {
position: relative;
width: 100%;
padding: 0 10px;
margin: 3rem;
box-sizing: border-box;
}

.sample-wrapper:active, .sample-wrapper:hover, .sample-wrapper:focus {
    outline: none;
    border: none;
}

.sample-card {

    /* margin: 1.8rem; */
    /* margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 2rem; */
    width: 100%;
    /* padding: 15px; */
    height: 380px;
    /* border-radius: 16px; */
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border-radius: 4%;
  box-shadow:  -4px -4px 8px #19191e95, -15px -15px 30px #2020251a;
/* border: 0.1px solid black; */
}

@media screen and (max-width: 480px){
    .sample-card{
        width: 100%;
    }

    .sample-card .details {
        bottom: 0;
    }

    .sample-card .card-image {
        transform: translateY(-100px);
    }

    .sample-wrapper{
        margin: 1rem 0px;
    }
    .sample-card:hover img{
        transition: none;
    }
}

.sample-card .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000;
    transition: .5s;
}

.sample-card .card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

    .sample-card:hover img {
        opacity: .2;
        transition: .5s;
    }

    .sample-card:hover {
        box-shadow: 0 0 0 0;
        /* box-shadow: 0px 4px 12px 6px rgba(2, 2, 2, 0.721), 0px 4px 16px rgba(12, 108, 167, 0.721); */
        border: 4.5px solid #002147;
    }



    .sample-card:hover .card-image {
    transform: translateY(-100px);
    transition: all .9s;
    }

    .sample-card:hover .social-icons li a {
    transform: translateY(0px);
    opacity: 1;
    }

    .sample-card:hover li:nth-child(1) a {
    transition-delay: .1s;
    }

    .sample-card:hover li:nth-child(2) a {
    transition-delay: .2s;
    }

    .sample-card:hover li:nth-child(3) a {
        transition-delay: .3s;
    }

    .sample-card:hover li:nth-child(4) a {
        transition-delay: .4s;
    }

    .details {
        position: absolute;
        bottom: 400px;
        left: 0;
        background: #fff;
        width: 100%;
        height: 120px;
        z-index: 1;
        padding: 10px;
        transition: .6s;
        font-family: 'Proza Libre', sans-serif;
        font-family: 'Quicksand', sans-serif;
    }

    .details h2 {
        margin: 30px 0;
        padding: 0;
        color: #000;
        text-align: center;
    }

    .details h2 .job-title {
        font-size: 16px;
        line-height: 2;
        /* color: #333; */
        color: black;
        font-weight: 300;
        display: block;
    }

    .sample-card:hover .details {
        bottom: 0;
    }

    .carousel .slick-dots {
        bottom: -2.5vw;
    }

    .carousel .slick-dots li button {
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 20px;
    }

    .carousel .slick-dots li button:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        content: "";
        text-align: center;
        opacity: .5;
        color: #000;
        background-color: #fff;
        transform: translate(-50%, -50%);
        transition: all .3s ease-in-out;
    }

    .carousel .slick-dots li.slick-active button, .carousel .slick-dots li:hover button {
        border-color: tomato;
    }

    .carousel .slick-dots li.slick-active button:before, .carousel .slick-dots li:hover button:before {
        background-color: tomato;
        opacity: 1;
    }

    .carousel .slick-prev, .carousel .slick-next {
        width: 50px;
        height: 50px;
        background-color: #fff;
    }

    .carousel .slick-prev:hover, .carousel .slick-prev:focus, .carousel .slick-next:hover, .carousel .slick-next:focus {
        color: #fff;
        outline: none;
        background: #fff;
    }

    .carousel .slick-prev:before, .carousel .slick-next:before {
        color: #000;
        font-family: "Raleway", sans-serif;
    }

    .carousel .slick-next {
    right: -55px;
    }

    .carousel .slick-prev {
    left: -55px;
    }

    /* slider */

    *, *::before, *::after {
        margin: 0;
        padding: 0;
      }
      
      /* body {
        background: #333;
        color: #f2f2f2;
        min-height: 100%;
        min-width: 100%;
      } */
      
      .container {
        width: 90%;
        margin: auto;
      }
      
      .cards-container {
        display: flex;
        border-radius: 6px;
        color: #333;
        /* background: #eee; */
        /* background: #fff; */
        padding: 1rem;
        box-shadow: 0 0 0.3rem #000 inset;
        overflow-x: auto;
        position: relative;
        scrollbar-color: rgba(0, 0, 0, 0);
      }

    .cards-container::-webkit-scrollbar {
        display: none;
    }

    /* .cards-container::-webkit-scrollbar-thumb {
       background: red;
       height: 20px;

    } */

      .cards {
        flex: 1 0 250px;
        box-shadow: 0 1px 1rem -4px #000;
        background: #fff;
        margin: 1rem;
        overflow: hidden;
        border-radius: 6px;
        height: 400px;
        cursor: pointer;
        transition: all 250ms ease-in-out;
      }  
        .cards img {
          width: 250px;
          height: 250px;
          object-fit: center;
        }
        
        .cards:hover {
          box-shadow: 0 4px 1rem -4px #000;
          transform: translateY(-1.5rem);
        }
      
      
      .cards-content {
        padding: 1.5rem;
        color: black;
      }

      .cards-content h2 {
          color: black;
          padding: 10px;
      }

      .blog-text{
          color: #000;
          padding: 8px;
      }
      
      @media screen and (max-width: 625px) {
        .container {
          width: 100%
        }
        
        .cards-container {
          padding: .5rem;
        }
        
        .cards {
          margin: .5rem;
        }
      }


    
